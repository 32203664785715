import $ from 'jquery';

$(document).ready(function () {
  $('.wpcf7-form-control').focus(function() {
    $(this).parent().addClass('focuson');
  }).blur(function(){
     $(this).parent().removeClass('focuson');
  });

  $('.wpcf7-form :input').focus(function() {
    $('label[for="' + this.id + '"]').addClass('labelfocus');
  }).blur(function() {
    $('label').removeClass('labelfocus');
  });
});



