/* global sprite_path */

import $ from 'jquery';

const prevArrow = `<div class="arrow-slick arrow-slick-prev" id="arrow-slick-prev"><svg class="svg-left-arrow-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#left-arrow"></use></svg></div>`;
const nextArrow = `<div class="arrow-slick arrow-slick-next" id="arrow-slick-next"><svg class="svg-right-arrow-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#right-arrow"></use></svg></div>`;

var $status = $('.paginginfo');
var $slickElement = $('.slideshow');

$slickElement.on('init reInit afterChange', function(event, slick, currentSlide){
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $status.html('0'+i + '</span><span>' + '0'+slick.slideCount);
});

$(document).ready(function($) {
  $('.slick-slider').slick({
    arrows: true,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 740,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  });
});

const prevArrowport = `<div class="arrow-slick arrow-slick-prev-portfolio" id="arrow-slick-prev"><svg class="svg-left-arrow-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#left-arrow"></use></svg></div>`;
const nextArrowport = `<div class="arrow-slick arrow-slick-next-portfolio" id="arrow-slick-next"><svg class="svg-right-arrow-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#right-arrow"></use></svg></div>`;



$(document).ready(function($) {
  $('.portfolio-slider').slick({
    arrows: true,
    prevArrow: prevArrowport,
    nextArrow: nextArrowport,
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    autoplay: false,
    autoplaySpeed: 2000,
  });
});

$('.portfolio-slider .slick-slide').not('.slick-current').addClass('inactive-slide');

$('.portfolio-slider').on('afterChange', function(){
  $('.portfolio-slider .slick-slide').removeClass('inactive-slide');
  $('.portfolio-slider .slick-slide').not('.slick-current').addClass('inactive-slide');
});


const prevArrowemployer = `<div class="arrow-slick arrow-slick-prev-employer" id="arrow-slick-prev"><svg class="svg-left-arrow-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#left-arrow"></use></svg></div>`;
const nextArrowemployer = `<div class="arrow-slick arrow-slick-next-employer" id="arrow-slick-next"><svg class="svg-right-arrow-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#right-arrow"></use></svg></div>`;



$(document).ready(function($) {
  $('.employer-slider').slick({
    arrows: true,
    prevArrow: prevArrowemployer,
    nextArrow: nextArrowemployer,
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    autoplay: false,
    autoplaySpeed: 2000,
  });
});

$('.employer-slider .slick-slide').not('.slick-current').addClass('inactive-slide');

$('.employer-slider').on('afterChange', function(){
  $('.employer-slider .slick-slide').removeClass('inactive-slide');
  $('.employer-slider .slick-slide').not('.slick-current').addClass('inactive-slide');
});


